import React, { useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Breadcrumb } from 'react-bootstrap';
import { useGetUserInfoQuery, useUpdateUserReportViewPriviledgesMutation } from '../../services/portal';
import { useParams, Link } from 'react-router-dom';

const ReportsGrid = () => {
  let { reportId, reportName } = useParams();

  const { data: userInfo, isLoading: isInfoLoading } = useGetUserInfoQuery(reportId, { forceRefetch: true });
  const [checkedState, setCheckedState] = useState({});
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    if (userInfo && userInfo.users) {
      const initialCheckedState = userInfo.users.reduce((acc, user) => {
        acc[user.UserId] = user.HasRights;
        return acc;
      }, {});
      setCheckedState(initialCheckedState);
    }
  }, [userInfo]);

  const [updateUserReportViewPrivileges] = useUpdateUserReportViewPriviledgesMutation();

  const handleOnChange = (rowData, checked) => {
    const { UserId } = rowData;
    const updatedCheckedState = { ...checkedState, [UserId]: checked };
    setCheckedState(updatedCheckedState);
  
    updateUserReportViewPrivileges({
      user_id: UserId,
      has_view_priviledges: checked,
      report_id: reportId
    })
    .unwrap()
    .then((payload) => {})
    .catch((error) => console.error('rejected', error));
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filterData = () => {
    if (!userInfo || !userInfo.users.length) return [];
    return userInfo.users.filter(item => {
      return Object.values(item).some(value => {
        if (typeof value === 'string') {
          return value.toLowerCase().includes(searchTerm.toLowerCase());
        }
        return false;
      });
    });
  };

  const filteredData = filterData();

  const columns = [
    { field: 'Organization', header: 'Organization' },
    { field: 'First_Name', header: 'First Name' },
    { field: 'Last_Name', header: 'Last Name' },
    { field: 'Phone', header: 'Phone' },
    { field: 'Email', header: 'Email' },
    { field: 'Company', header: 'Company' },
    { field: 'Department', header: 'Department' },
    { field: 'Role', header: 'Role' }
  ];

  return (
    <div className="page-inner">
      <Breadcrumb className="breadcrumb">
        <Breadcrumb.Item
          className="active"
          linkProps={{ to: '/reports' }}
          linkAs={Link}>
          <i className="breadcrumb-icon fa fa-angle-left mr-2"></i> Reports
        </Breadcrumb.Item>
      </Breadcrumb>
      <header className="page-title-bar">
        <h1 className="page-title">
          <i className="fas fa-user text-muted mr-2"></i>User Assignment: {reportName}
        </h1>
      </header>
      
      <div className="page border">
        {userInfo && userInfo.users && (
          <div className="datatable-custom">
            <div className="m-0 justify-content-end d-flex align-items-end p-3">
              <input 
                type="text" 
                className="m-0 col-2 card d-flex align-items-end p-2"
                placeholder="Type to Search..." 
                value={searchTerm} 
                onChange={handleSearchChange} 
              />
              <span className="p-inputgroup-addon">
                <i className="pi pi-search"></i>
              </span>
            </div>
            <DataTable
              value={filteredData}
              removableSort
              tableClassName="table dataTable no-footer table-striped"
            >
              {columns.map(col => (
                <Column key={col.field} field={col.field} header={col.header} sortable />
              ))}
              <Column header="Has Rights" body={(rowData) => (
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id={`checkbox-${rowData.UserId}`}
                    checked={checkedState[rowData.UserId]}
                    onChange={(e) => handleOnChange(rowData, e.target.checked)}
                  />
                  <label className="custom-control-label" htmlFor={`checkbox-${rowData.UserId}`}></label>
                </div>
              )} />
            </DataTable>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReportsGrid;

import React, { useState } from 'react'

import MyReports from '../../components/MyReports/MyReports';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSearch} from '@fortawesome/free-solid-svg-icons'

import { ReactDOM } from 'react';



export const SearchBar = () => {
    const [input, setInput] = useState("");
  
    // FetchData component
    const FetchData = ({ values }) => {
      return (
        <div>
            
          <MyReports SearchValue={values} />
        </div>
      );
    }
  
    const HandleChange = (value) => {
      setInput(value);
      //FetchData(value)
      //return <FetchData values={value} />;
    };
    const SeatchBarStyle = {
       padding : '10 px'
    }
  
    return (
      <div className='input-wrapper '>
        <div className='row m-0'>
            <label className="form-group col m-0">Search all reports </label>
        </div>
        <div className='row m-0 mb-2'>
            <FontAwesomeIcon icon={faSearch} className='mt-1 align-items-start pr-2'/>
            <input placeholder='type to search...' className="col-6 col-sm-3 align-items-start card" value={input} onChange={(e) => HandleChange(e.target.value)} style={SeatchBarStyle}/>
        </div>
        <FetchData values={input} />
      </div>
    );
  }
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useGetWorkspacesQuery } from '../../services/portal';
import DataGrid from '../../components/Users/DataGrid'; // Assuming DataGrid component is in the correct directory

const Workspaces = () => {
  let navigate = useNavigate();

  const { data: workspaces } = useGetWorkspacesQuery({
    forceRefetch: true
  });

  const handleNewWorkspace = () => {
    navigate('/workspaces/new');
  };

  const handleEditWorkspace = (rowData) => {
    // Navigate to edit workspace page
    navigate(`/workspaces/${rowData.workspace_id}`);
  };

  const columns = [
    { field: 'organization.organization_name', header: 'Organization' },
    { field: 'workspace_name', header: 'Name' },
    { field: 'workspace_code', header: 'Code' },
    { field: 'workspace_description', header: 'Description' },
    { 
      field: 'actions', 
      header: ' ', 
      body: (rowData) => (
        <div className="text-center">
          <button className="btn btn-sm btn-icon btn-secondary" onClick={() => handleEditWorkspace(rowData)}>
            <i className="fa fa-pencil-alt"></i>
            <span className="sr-only">Edit</span>
          </button>
          {/* Conditionally render delete button */}
          {rowData.showDelete && (
            <button className="btn btn-sm btn-icon btn-secondary" onClick={() => handleDeleteWorkspace(rowData)}>
              <i className="fa fa-trash-alt" style={{ marginBottom: '3px' }}></i>
              <span className="sr-only">Delete</span>
            </button>
          )}
        </div>
      )
    }
  ];

  return (
    <div className="page">
      <div className="page-inner">
        <header className="page-title-bar">
          <div className="d-flex justify-content-between">
            <h1 className="page-title">
              <i className="far fa-object-group text-muted mr-2"></i>Workspaces
            </h1>
            <div className="btn-toolbar">
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleNewWorkspace}>
                + New
              </button>
            </div>
          </div>
        </header>
        <div className="page-section">
          <div className="card card-fluid">
            <div className="table-responsive">
              {/* Pass showDelete prop to DataGrid */}
              <DataGrid 
                  data={workspaces?.workspaces} 
                  columns={columns} 
                  onEditRow={handleEditWorkspace} 
                  showDelete={false} // Hide the delete button
                  showEditRow={true}
                />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Workspaces;

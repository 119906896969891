import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import InternalServer from './Error/InternalServer';
import Home from './Home';
import Submitted from './Support/Submitted';
import Ticket from './Support/Ticket';
import NavigationBar from '../components/NavigationBar/NavigationBar';
import CreateSubscription from '../components/Notifications/Subscriptions/CreateSubscription';
import Subscriptions from '../components/Notifications/Subscriptions/Subscriptions';
import CreateOrganization from '../components/Organization/CreateOrganization';
import EditOrganization from '../components/Organization/EditOrganization';
import Organizations from '../components/Organization/Organizations';
import Branding from '../components/Preferences/Branding/Branding';
import SMTP from '../components/Preferences/SMTP/SMTP';
import CreateReport from '../components/Reports/CreateReport';
import EditReport from '../components/Reports/EditReport';
import Reports from '../components/Reports/Reports';
import ReportView from '../components/ReportView';
import CreateUser from '../components/Users/CreateUser';
import EditUser from '../components/Users/EditUser';
import UserAssignment from '../components/Reports/UserAssignment';
import Users from '../components/Users/Users';
import CreateWorkspace from '../components/Workspace/CreateWorkspace';
import EditWorkspace from '../components/Workspace/EditWorkspace';
import Workspaces from '../components/Workspace/Workspaces';

import 'react-toastify/dist/ReactToastify.css';


toast.configure();

const Layout = () => {
  return (
    <React.Fragment>
      <NavigationBar />
      <ToastContainer position="top-right" autoClose={false} />
      <main className="app-main">
        <div className="wrapper">
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/organizations" element={<Organizations />} />
            <Route path="/organizations/new" element={<CreateOrganization />} />
            <Route
              path="/organizations/:organizationId"
              element={<EditOrganization />}
            />
            <Route exact path="/users" element={<Users />} />
            <Route path="/users/new" element={<CreateUser />} />
            <Route path="/users/:userId" element={<EditUser />} />
            <Route exact path="/workspaces" element={<Workspaces />} />
            <Route path="/workspaces/new" element={<CreateWorkspace />} />
            <Route
              path="/workspaces/:workspaceId"
              element={<EditWorkspace />}
            />
            <Route exact path="/reports" element={<Reports />} />
            <Route path="/reports/new" element={<CreateReport />} />
            <Route path="/reports/:reportId" element={<EditReport />} />
            <Route
              path="/report/:reportType/:workspaceId/:workspaceCode/:reportId/:reportCode/:reportName"
              element={<ReportView />}
            />
            <Route path="/preferences/branding" element={<Branding />} />
            <Route path="/preference/smtp" element={<SMTP />} />
            <Route
              path="/notifications/subscriptions/reports/:reportType/:reportId/:reportCode/:reportName"
              element={<Subscriptions />}
            />
            <Route
              path="/notifications/subscriptions/reports/:reportType/:reportId/:reportCode/:reportName/new"
              element={<CreateSubscription />}
            />
            <Route
              path="/notifications/subscriptions/reports/:reportType/:reportId/:reportCode/:reportName/edit"
              element={<CreateSubscription />}
            />
            <Route
              path="/Reports/UserAssignment/ReportsGrid/:reportId/:reportName"
              element={<UserAssignment />}
            />
            <Route path="/support/ticket" element={<Ticket />} />
            <Route path="/support/submitted" element={<Submitted />} />
            <Route path="/error/500" element={<InternalServer />} />
          </Routes>
        </div>
      </main>
    </React.Fragment>
  );
};

export default Layout;

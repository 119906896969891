import { useState, useEffect } from 'react';
import MyReports from '../components/MyReports/MyReports';
import { SearchBar } from './Components/SearchBar';
import axios from 'axios';

function getSessionStorageOrDefault(key, defaultValue) {
  const stored = sessionStorage.getItem(key);
  if (!stored) {
    return defaultValue;
  }
  return JSON.parse(stored);
}

const Home = () => {
  const [user, setUser] = useState(getSessionStorageOrDefault('user', null));
  //const [forecast, setForecast] = useState([]);
  //const [error, setError] = useState(null);

  useEffect(() => {
    // Any additional initialization logic can go here
  }, []);

  // const handlePostToAPI = () => {
  //   const apiHostAddress = 'https://localhost:7019/WeatherForecast';

  //   axios.get(apiHostAddress, {
  //     headers: {
  //       Accept: 'application/json',
  //     },
  //   })
  //     .then(response => {
  //       setForecast(response.data);
  //       setError(null);
  //       console.log(response);
  //     })
  //     .catch(err => {
  //       setError('Error fetching weather forecast');
  //       console.error('There was an error!', err);
  //     });
  // };

  const formattedDate = user ? new Date(user.date).toLocaleDateString() : '';

  return (
    <div className="page">
      <div className="page-inner">
        <header className="page-title-bar">
          <div className="d-flex flex-column flex-md-row">
            <p className="lead">
              <span className="font-weight-bold">Good day, {user.name}.</span>
              <span className="d-block text-muted">
                Find and share actionable insights to make data-driven decisions.
              </span>
            </p>
          </div>
        </header>
        <div className="my-reports-container">
          <SearchBar />
          {/* Button to post to API
          <button onClick={handlePostToAPI} className="btn btn-primary">
            Post to API
          </button>

          {/* Display API data or error */}
          {/* {error ? (
            <p>{error}</p>
          ) : (
            <ul>
              {forecast.map((weather, index) => (
                <li key={index}>
                  <p>Date: {weather.date}</p>
                  <p>Temperature (C): {weather.temperatureC}</p>
                  <p>Temperature (F): {weather.temperatureF}</p>
                  <p>Summary: {weather.summary}</p>
                </li>
              ))}
            </ul>
          )} }*/ }
        </div>
      </div>
    </div>
  );
};

export default Home;

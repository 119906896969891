import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Define a service using a base URL and expected endpoints
export const processorServiceApi = createApi({
  reducerPath: 'processorServiceApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_PROCESSOR_SERVICE_API
  }),
  keepUnusedDataFor: 30,
  refetchOnMountOrArgChange: 30,
  tagTypes: ['Reports'],
  endpoints: (builder) => ({
    getReportSubscription: builder.query({
      query: (reportId) => `subscriptions/reports/${reportId}`,
      providesTags: ['Reports']
    }),
    
    addReportSubscription: builder.mutation({
      query(data) {
        console.log(data);
        return {
          url: 'subscriptions/reports/',
          method: 'POST',
          body: data
        };
      },
      invalidatesTags: ['Reports']
    }),
    updateReportSubscription: builder.mutation({
      query({ jobId, ...patch }) {
        return {
          url: `subscriptions/reports/${jobId}`,
          method: 'PUT',
          body: patch
        };
      },
      invalidatesTags: ['Organizations']
    }),
    deleteReportSubscription: builder.mutation({
      query: (jobId) => ({
        url: `subscriptions/reports/${jobId}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Reports']
    }),
    
  })
});

export const {
  useGetReportSubscriptionQuery,
  
  useAddReportSubscriptionMutation,
  useUpdateReportSubscriptionMutation,
  useDeleteReportSubscriptionMutation
} = processorServiceApi;

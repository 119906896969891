import React from 'react';

const TimeAndSchedule = ({ createdAt, schedule }) => {
  // Convert createdAt to a Date object
  const createdDate = new Date(createdAt);

  // Extract the date part from the createdAt date
  const formattedCreatedAt = createdDate.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  return (
    <div>
      <p>Created: {formattedCreatedAt}</p>
      <p>Schedule: {schedule}</p>
    </div>
  );
};

export default TimeAndSchedule;
import { useEffect, useState } from 'react';
import { Button, Breadcrumb, Form, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import {
  useGetSMTPSettingsQuery,
  useAddSMTPSettingsMutation,
  useAddTestMailMutation
} from '../../../services/portal';
import DismissibleAlert from '../../AlertDismissible/DismissibleAlert';

function getSessionStorageOrDefault(key, defaultValue) {
  const stored = sessionStorage.getItem(key);
  if (!stored) {
    return defaultValue;
  }
  return JSON.parse(stored);
}

const SMTP = () => {
  let navigate = useNavigate();

  const [user, setUser] = useState(getSessionStorageOrDefault('user', null));
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertVariant, setAlertVariant] = useState('info');
  const [useDefaultSmtp, setUseDefaultSmtp] = useState();
  const [tls, setTls] = useState();
  const [authenticationRequired, setAuthenticationRequired] = useState();

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors }
  } = useForm();

  const { data: smtpSettings } = useGetSMTPSettingsQuery(user.oid, {
    forceRefetch: true,
    refetchOnMountOrArgChange: true
  });
  const [addSMTPSettings, { isLoading }] = useAddSMTPSettingsMutation();
  const [addTestMail] = useAddTestMailMutation();

  useEffect(() => {
    if (smtpSettings) {
      setValue('name', smtpSettings.name);
      setValue('description', smtpSettings.description);
      setValue('fromAddress', smtpSettings.from_address);
      setValue('hostName', smtpSettings.host);
      setValue('port', smtpSettings.port == null ? 25 : smtpSettings.port);
      setValue(
        'timeout',
        smtpSettings.timeout == null ? 10000 : smtpSettings.timeout
      );
      setValue('username', smtpSettings.username);
      setValue('password', smtpSettings.password);

      setUseDefaultSmtp(smtpSettings.use_default_smtp);
      setTls(smtpSettings.secure);
      setAuthenticationRequired(smtpSettings.authentication_required);
    }
  }, [smtpSettings, setValue]);

  const handleCancel = () => {
    navigate('/');
  };

  const onSubmit = (formData) => {
    addSMTPSettings({
      organization_id: user.oid,
      use_default_smtp: useDefaultSmtp,
      name: formData.name,
      description: formData.description,
      from_address: formData.fromAddress,
      host: formData.hostName,
      port: formData.port,
      timeout: formData.timeout,
      secure: tls,
      authentication_required: authenticationRequired,
      username: formData.username,
      password: formData.password
    })
      .unwrap()
      .then(() => {
        setAlertMessage('SMTP settings saved successfully.');
        setAlertVisible(true);
        setAlertVariant('info');
      })
      .catch((error) => {
        setAlertVariant('danger');
        setAlertMessage(error.data.message);
        setAlertVisible(true);
      });
  };

  const testSMTPServer = () => {
    const formData = getValues();
    addTestMail({
      organization_id: user.oid,
      use_default_smtp: useDefaultSmtp,
      name: formData.name,
      description: formData.description,
      from_address: formData.fromAddress,
      host: formData.hostName,
      port: formData.port,
      timeout: formData.timeout,
      secure: tls,
      authentication_required: authenticationRequired,
      username: formData.username,
      password: formData.password
    })
      .unwrap()
      .then(() => {
        setAlertMessage('Mail Sent Successfully.');
        setAlertVisible(true);
        setAlertVariant('info');
      })
      .catch((error) => {
        setAlertVariant('danger');
        setAlertMessage(error.data.message);
        setAlertVisible(true);
      });
  };

  return (
    <div className="page">
      <div className="page-inner">
        <header className="page-title-bar">
          <Breadcrumb className="breadcrumb">
            <Breadcrumb.Item
              className="active"
              linkProps={{ to: '/' }}
              linkAs={Link}>
              <i className="breadcrumb-icon fa fa-angle-left mr-2"></i> Home
            </Breadcrumb.Item>
          </Breadcrumb>
          <h1 className="page-title"> SMTP Settings </h1>
          <p className="text-muted">
            Manage SMTP mail server configurations that will be used to send all
            outgoing mail.
          </p>
        </header>
        <div className="page-section">
          <div id="labels" className="card">
            <div className="card-body">
              <Form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <h6>SMTP Mail Server</h6>
                <Form.Group className="form-group col-md-6 mb-3">
                  <Form.Check
                    bsPrefix="custom-control"
                    name="smtpType"
                    type="radio"
                    id="smtpDefault"
                    label="Use default SMTP mail server"
                    defaultChecked={useDefaultSmtp}
                    onClick={() => setUseDefaultSmtp(true)}
                  />
                  <Form.Check
                    bsPrefix="custom-control"
                    name="smtpType"
                    type="radio"
                    id="smtpOwn"
                    label="Use own SMTP mail server"
                    defaultChecked={!useDefaultSmtp}
                    onClick={() => setUseDefaultSmtp(false)}
                  />
                </Form.Group>

                <h6>Add own SMTP Mail Server</h6>
                <Form.Group className="form-group col-md-6 mb-3">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    id="name"
                    placeholder=""
                    {...register('name', {
                      minLength: 3,
                      maxLength: 60,
                      required: true
                    })}
                  />
                  {errors?.name && errors.name.type === 'required' && (
                    <p className="validate-feedback-invalid">
                      Name is required.
                    </p>
                  )}
                  {errors?.name && errors.name.type === 'minLength' && (
                    <p className="validate-feedback-invalid">
                      Name must be at least 3 characters.
                    </p>
                  )}
                  {errors?.name && errors.name.type === 'maxLength' && (
                    <p className="validate-feedback-invalid">
                      Name must not be longer than 60 characters.
                    </p>
                  )}
                  <small className="text-muted">
                    The name of this server within BI Portal.
                  </small>
                </Form.Group>
                <Form.Group className="form-group col-md-6 mb-3">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    type="text"
                    id="description"
                    placeholder=""
                    {...register('description', {
                      maxLength: 100,
                      required: false
                    })}
                  />
                  {errors?.description &&
                    errors.description.type === 'maxLength' && (
                      <p className="validate-feedback-invalid">
                        Description must not be longer than 100 characters.
                      </p>
                    )}
                  <small className="text-muted">
                    The description of this server within BI Portal.
                  </small>
                </Form.Group>
                <Form.Group className="form-group col-md-6 mb-3">
                  <Form.Label>From Address</Form.Label>
                  <Form.Control
                    type="text"
                    id="fromAddress"
                    placeholder="noreply@mydomain.co.za"
                    {...register('fromAddress', {
                      minLength: 5,
                      maxLength: 60,
                      required: true
                    })}
                  />
                  {errors?.fromAddress &&
                    errors.fromAddress.type === 'required' && (
                      <p className="validate-feedback-invalid">
                        From Address is required.
                      </p>
                    )}
                  {errors?.fromAddress &&
                    errors.fromAddress.type === 'minLength' && (
                      <p className="validate-feedback-invalid">
                        From Address must be at least 5 characters.
                      </p>
                    )}
                  {errors?.fromAddress &&
                    errors.fromAddress.type === 'maxLength' && (
                      <p className="validate-feedback-invalid">
                        From Address must not be longer than 60 characters.
                      </p>
                    )}
                  <small className="text-muted">
                    The default address this server will use to send email from.
                  </small>
                </Form.Group>
                <h6>SMTP Server Details</h6>
                <Form.Group className="form-group col-md-6 mb-3">
                  <Form.Label>SMTP Host Name</Form.Label>
                  <Form.Control
                    type="text"
                    id="hostName"
                    placeholder=""
                    {...register('hostName', {
                      minLength: 5,
                      maxLength: 60,
                      required: true
                    })}
                  />
                  {errors?.hostName && errors.hostName.type === 'required' && (
                    <p className="validate-feedback-invalid">
                      Host Name is required.
                    </p>
                  )}
                  {errors?.hostName && errors.hostName.type === 'minLength' && (
                    <p className="validate-feedback-invalid">
                      Host Name must be at least 5 characters.
                    </p>
                  )}
                  {errors?.hostName && errors.hostName.type === 'maxLength' && (
                    <p className="validate-feedback-invalid">
                      Host Name must not be longer than 60 characters.
                    </p>
                  )}
                  <small className="text-muted">
                    The fully qualified host name of this server.
                  </small>
                </Form.Group>
                <Form.Group className="form-group col-md-6 mb-3">
                  <Form.Label>SMTP Port</Form.Label>
                  <Form.Control
                    type="text"
                    id="port"
                    placeholder=""
                    {...register('port', {
                      minLength: 1,
                      maxLength: 60,
                      required: true
                    })}
                  />
                  {errors?.port && errors.port.type === 'required' && (
                    <p className="validate-feedback-invalid">Port is required.</p>
                  )}
                  {errors?.port && errors.port.type === 'minLength' && (
                    <p className="validate-feedback-invalid">
                      Port must be at least 1 character.
                    </p>
                  )}
                  {errors?.port && errors.port.type === 'maxLength' && (
                    <p className="validate-feedback-invalid">
                      Port must not be longer than 60 characters.
                    </p>
                  )}
                </Form.Group>
                <Form.Group className="form-group col-md-6 mb-3">
                  <Form.Label>Timeout</Form.Label>
                  <Form.Control
                    type="text"
                    id="timeout"
                    placeholder=""
                    {...register('timeout', {
                      minLength: 1,
                      maxLength: 60,
                      required: false
                    })}
                  />
                </Form.Group>
                <Form.Group className="form-group col-md-6 mb-3">
                  <Form.Label>Username</Form.Label>
                  <Form.Control
                    type="text"
                    id="username"
                    placeholder=""
                    {...register('username', {
                      minLength: 1,
                      maxLength: 60,
                      required: true
                    })}
                  />
                  {errors?.username &&
                    errors.username.type === 'required' && (
                      <p className="validate-feedback-invalid">
                        Username is required.
                      </p>
                    )}
                  {errors?.username &&
                    errors.username.type === 'minLength' && (
                      <p className="validate-feedback-invalid">
                        Username must be at least 1 character.
                      </p>
                    )}
                  {errors?.username &&
                    errors.username.type === 'maxLength' && (
                      <p className="validate-feedback-invalid">
                        Username must not be longer than 60 characters.
                      </p>
                    )}
                </Form.Group>
                <Form.Group className="form-group col-md-6 mb-3">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    id="password"
                    placeholder=""
                    {...register('password', {
                      minLength: 1,
                      maxLength: 60,
                      required: true
                    })}
                  />
                  {errors?.password &&
                    errors.password.type === 'required' && (
                      <p className="validate-feedback-invalid">
                        Password is required.
                      </p>
                    )}
                  {errors?.password &&
                    errors.password.type === 'minLength' && (
                      <p className="validate-feedback-invalid">
                        Password must be at least 1 character.
                      </p>
                    )}
                  {errors?.password &&
                    errors.password.type === 'maxLength' && (
                      <p className="validate-feedback-invalid">
                        Password must not be longer than 60 characters.
                      </p>
                    )}
                </Form.Group>
                <Form.Group className="form-group col-md-6 mb-3">
                  <Form.Check
                    type="checkbox"
                    label="Use TLS"
                    id="tls"
                    checked={tls}
                    onChange={(e) => setTls(e.target.checked)}
                  />
                </Form.Group>
                <Form.Group className="form-group col-md-6 mb-3">
                  <Form.Check
                    type="checkbox"
                    label="Authentication Required"
                    id="authenticationRequired"
                    checked={authenticationRequired}
                    onChange={(e) =>
                      setAuthenticationRequired(e.target.checked)
                    }
                  />
                </Form.Group>
                <div className="form-actions">
                  <Button variant="primary" type="submit" disabled={isLoading}>
                    {isLoading ? (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          style={{ marginRight: 5 }}
                        />
                        Submitting...
                      </>
                    ) : (
                      'Save'
                    )}
                  </Button>
                  <Button
                    variant="secondary"
                    style={{ marginLeft: 10 }}
                    type="button"
                    onClick={handleCancel}>
                    Cancel
                  </Button>
                  <Button
                    variant="info"
                    style={{ marginLeft: 10 }}
                    type="button"
                    onClick={testSMTPServer}>
                    Test SMTP Server
                  </Button>
                </div>
                
              </Form>
              <DismissibleAlert
                message={alertMessage}
                variant={alertVariant}
                show={alertVisible}
                setShow={setAlertVisible}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SMTP;

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetReportsQuery, useUpdateReportStatusMutation } from '../../services/portal';
import DataGrid from '../../components/Users/DataGrid'; // Import the DataGrid component
import { Modal, Button, Tab, Tabs } from 'react-bootstrap';

const Reports = () => {
  let navigate = useNavigate();

  const { data: reports, refetch: refetchReports } = useGetReportsQuery({ forceRefetch: true });
  const [updateReportStatus, { isLoading: isUpdating }] = useUpdateReportStatusMutation();

  const [selectedReportId, setSelectedReportId] = useState(0);
  const [selectedReportWorkspace, setSelectedReportWorkspace] = useState('');
  const [selectedReportName, setSelectedReportName] = useState('');
  const [selectedReportStatus, setSelectedReportStatus] = useState(false);

  const [reportModalShow, setReportModalShow] = useState(false);

  const handleNewReport = () => {
    navigate('/reports/new');
  };

  const handleEditReport = (reportId) => {
    navigate(`/reports/${reportId.report_id}`);
  };

  const handleReportModalShow = (reportId, workspace, name, status) => {
  setSelectedReportId(reportId.report_id); // Extract the ID from the reportId object
  setSelectedReportWorkspace(reportId.workspace);
  setSelectedReportName(reportId.report_name);
  setSelectedReportStatus(reportId.active);
  setReportModalShow(true);
  console.log(reportId);
};

  const handleReportModalCancel = () => {
    setReportModalShow(false);
  };

  const handleSaveReportStatus = () => {
    console.log("handleSaveReportStatus called");
    const status = !selectedReportStatus; // Toggle the status
    console.log("Status:", status);
  
    // Update the report status
    updateReportStatus({
      report_id: selectedReportId,
      report_active: status
    }).unwrap()
      .then(() => {
        console.debug('Report status successfully updated');
        // Optionally, you can refetch data here
        refetchReports(); // Refetch the reports after updating status
      })
      .catch((error) => console.error('Failed to update report status:', error));
  
    setReportModalShow(false);
  };
  const handleNotification = (reportId) => {
    // Implement your logic here for handling notifications
    navigate(`/notifications/subscriptions/reports/${reportId.report_type}/${reportId.report_id}/${reportId.report_code}/${reportId.report_name}`);
  };
  const handleRights = (reportId) => {
    
    navigate(`/Reports/UserAssignment/ReportsGrid/${reportId.report_id}/${reportId.report_name}`);
  };
  
  const columns = [
    { field: 'organization.organization_name', header: 'Organization' },
    { field: 'workspace.workspace_name', header: 'Workspace' },
    { field: 'report_name', header: 'Name' },
    { field: 'report_code', header: 'Code' },
    { field: 'report_description', header: 'Description' },
    // Optionally, you can add more columns as needed
  ];

  return (
    <div className="page-inner">
      <header className="page-title-bar">
        <div className="d-flex justify-content-between">
          <h1 className="page-title">
            <i className="fas fa-chart-pie text-muted mr-2"></i>Reports
          </h1>
          <div className="btn-toolbar">
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleNewReport}>
              + New
            </button>
          </div>
        </div>
      </header>
      <div className="page-section">
        <Tabs defaultActiveKey="active" id="report-tabs">
          <Tab eventKey="active" title="Active">
            <div className="card p-3">
              <DataGrid
                data={reports?.reports.filter(report => report.active)}
                columns={columns}
                onEditRow={handleEditReport}
                onDeleteRow={handleReportModalShow}
                onNotification={handleNotification} // Pass the notification handler function
                onRights={handleRights}
                showDelete={true}
                showNotification={true}
                showEditRow={true}
                showRights={true}
              />
            </div>
          </Tab>
          <Tab eventKey="disabled" title="Disabled">
            <div className="card p-3">
              <DataGrid
                data={reports?.reports.filter(report => !report.active)}
                columns={columns}
                onEditRow={handleEditReport}
                onDeleteRow={handleReportModalShow}
                onNotification={handleNotification} // Pass the notification handler function
                onRights={handleRights}
                showDelete={true}
                showNotification={true}
                showEditRow={true}
                showRights={true}

              />
            </div>
          </Tab>
        </Tabs>
      </div>
      <Modal
        show={reportModalShow}
        onHide={handleReportModalCancel}
        animation={false}
        centered>
        <Modal.Header closeButton={false}>
          <Modal.Title>
            <h6>{selectedReportStatus ? 'Disable report' : 'Activate report'}</h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Are you sure you want to {selectedReportStatus ? 'Disable' : 'Activate'} <strong>{selectedReportName}</strong>?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleReportModalCancel}>Cancel</Button>
          <Button variant="warning" onClick={handleSaveReportStatus}>
            {selectedReportStatus ? 'Disable report' : 'Activate report'}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Reports;

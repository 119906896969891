import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useGetOrganizationsQuery } from '../../services/portal';
import DataGrid from '../../components/Users/DataGrid'; 

const Organizations = () => {
  let navigate = useNavigate();

  const { data: organizations } = useGetOrganizationsQuery({
    forceRefetch: true
  });

  console.log("Organizations data:", organizations);
  
  const handleNewOrganization = () => {
    navigate('/organizations/new');
  };

  const handleEditOrganization = (organizationId) => {
    navigate(`/organizations/${organizationId.organization_id}`);
  };

  const handleDeleteOrganization = (rowData) => {
    // Handle delete organization action
    console.log("Delete organization:", rowData.organization_id);
    
  };

  const columns = [
    { field: 'organization_name', header: 'Name' },
    { field: 'Primary_Contact', header: 'Primary Contact' },
    { field: 'Secondary_Contact',header: 'Secondary Contact'},
    { 
      field: 'actions', 
      header: ' ', 
      body: (rowData) => (
        <div className="text-center">
          <button className="btn btn-sm btn-icon btn-secondary" onClick={() => handleEditOrganization(rowData.organization_id)}>
            <i className="fa fa-pencil-alt"></i>
            <span className="sr-only">Edit</span>
          </button>
          
        </div>
      )
    }
  ];
  
  
  return (
    <div className="page">
      <div className="page-inner">
        <header className="page-title-bar">
          <div className="d-flex justify-content-between">
            <h1 className="page-title">
              <i className="far fa-building text-muted mr-2"></i>Organizations{' '}
            </h1>
            <div className="btn-toolbar">
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleNewOrganization}>
                + New
              </button>
            </div>
          </div>
        </header>

        <div className="page-section">
          <div className="card card-fluid">
            <div className="table-responsive">
              <DataGrid 
                  data={organizations?.organizations} 
                  columns={columns} 
                  onEditRow={handleEditOrganization} 
                   showEditRow={true}
/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Organizations;

import { useCallback, useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactTags } from 'react-tag-autocomplete';
import * as REPORT from '../../constants/reports';
import {
  useAddReportMutation,
  useGetOrganizationsQuery,
  useGetWorkspacesByOrganizationQuery,
  useGetAvailableReportCodesQuery,
  useGetReportQuery,
  useUpdateReportMutation
} from '../../services/portal';

const CreateEditReport = ({ isCreate }) => {
  let navigate = useNavigate();
  let { reportId } = useParams();

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors }
  } = useForm();

  const [selectedOrganization, setSelectedOrganization] = useState(0);
  const [selectedWorkspace, setSelectedWorkspace] = useState(null);
  const [reportType, setReportType] = useState('');
  const [isRowLevelSecurity, setIsRowLevelSecurity] = useState(false);
  const [isSSASSecurity, setIsSSASSecurity] = useState(false);
  const [availableReportCodes, setAvailableReportCodes] = useState([]);
  const [selected, setSelected] = useState([]);

  const { data: organizations } = useGetOrganizationsQuery({
    forceRefetch: true
  });
  const { data: workspaces } = useGetWorkspacesByOrganizationQuery(
    selectedOrganization,
    {
      forceRefetch: true
    }
  );
  const { data: reportCodes, isLoading: isReportCodesLoading } = useGetAvailableReportCodesQuery(
    selectedWorkspace?.workspace_code,
    {
      forceRefetch: true
    }
  );

  const { data: report } = useGetReportQuery(reportId, {
    skip: !reportId,
    forceRefetch: true
  });

  const [addReport, { isLoading: isAdding }] = useAddReportMutation();
  const [updateReport, { isLoading: isUpdating }] = useUpdateReportMutation();

  const handleOrganizationChange = (e) => {
    const selectedOrganizationId = e.target.value;
    setSelectedOrganization(selectedOrganizationId);
    setSelectedWorkspace(null);
  };

  const handleWorkspaceChange = (e) => {
    const selectedWorkspaceId = e.target.value;
    const selectedWorkspaceData = workspaces?.workspaces.find(workspace => workspace.workspace_id === selectedWorkspaceId);
    if (selectedWorkspaceData) {
      setSelectedWorkspace(selectedWorkspaceData);
    }
  };

  const handleCodeChange = (e) => {
    const selectedCode = e.target.value;
    const selectedIndex = availableReportCodes.indexOf(selectedCode);
    if (selectedIndex !== -1) {
      const selectedReportType = reportCodes.reportType[selectedIndex];
      setReportType(selectedReportType);
    }
  };

  useEffect(() => {
    if (organizations && organizations.organizations.length > 0) {
      const initialOrganization = isCreate ? organizations.organizations[0].organization_id : report?.organization.organization_id;
      setValue('organization', initialOrganization);
      setSelectedOrganization(initialOrganization);
    }
  }, [organizations, isCreate, report, setValue]);

  useEffect(() => {
    if (workspaces && workspaces.workspaces.length > 0) {
      const initialWorkspace = isCreate ? workspaces.workspaces[0] : report?.workspace;
      setValue('workspace', initialWorkspace?.workspace_id);
      setSelectedWorkspace(initialWorkspace);
    }
  }, [workspaces, isCreate, report, setValue]);

  useEffect(() => {
    if (reportCodes && reportCodes.reportCodes) {
      setAvailableReportCodes(reportCodes.reportCodes);
    }
  }, [reportCodes]);

  useEffect(() => {
    if (isCreate) return;

    if (report) {
      setSelectedOrganization(report.organization.organization_id);
      setSelectedWorkspace(report.workspace);
      setValue('organization', report.organization.organization_id);
      setValue('workspace', report.workspace.workspace_id);
      setValue('name', report.report_name);
      setValue('shortName', report.report_shortname);
      setValue('code', report.report_code);
      setValue('description', report.report_description);
      setValue('username', report.report_ssas.username);
      setReportType(report.report_type);
      setIsRowLevelSecurity(report.report_rls.enabled);
      setIsSSASSecurity(report.report_ssas.enabled);
      setSelected(
        report.report_rls.roles.map((role) => {
          return { value: role, label: role };
        })
      );
    }
  }, [report, isCreate, setValue]);

  const onSubmit = (formData) => {
    const selectedIndex = availableReportCodes.indexOf(formData.code);
    let selectedReportType = reportCodes.reportType[selectedIndex];
    if (selectedReportType === 'PowerBIReport') {
      selectedReportType = 'Report';
    } else {
      selectedReportType = 'Paginated Report';
    }

    const mutationVariables = {
      report_name: formData.name,
      report_shortname: formData.shortName,
      report_code: formData.code,
      report_description: formData.description,
      report_type: selectedReportType,
      report_rls: {
        enabled: isRowLevelSecurity,
        roles:
          reportType === REPORT.TYPE.PAGINATED
            ? []
            : selected.map((select) => select.value)
      },
      report_ssas: {
        enabled: isSSASSecurity,
        username: formData.username
      },
      workspace: {
        workspace_id: formData.workspace
      },
      organization: {
        organization_id: formData.organization
      }
    };

    if (isCreate) {
      addReport(mutationVariables)
        .unwrap()
        .then(() => {
          navigate('/reports');
        })
        .catch((error) => console.error('rejected', error));
    } else {
      updateReport({ report_id: reportId, ...mutationVariables })
        .unwrap()
        .then(() => {
          navigate('/reports');
        })
        .catch((error) => console.error('rejected', error));
    }
  };

  const onAdd = useCallback(
    (newTag) => {
      setSelected([...selected, newTag]);
    },
    [selected]
  );

  const onDelete = useCallback(
    (tagIndex) => {
      setSelected(selected.filter((_, i) => i !== tagIndex));
    },
    [selected]
  );

  const handleCancel = () => {
    navigate('/reports');
  };

  return (
    <div className="page">
      <div className="page-inner">
        <header className="page-title-bar">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/reports">
                  <i className="breadcrumb-icon fa fa-angle-left mr-2"></i>Reports
                </a>
              </li>
              <li className="breadcrumb-item active">Create Report</li>
            </ol>
          </nav>
          <h1 className="page-title">Create Report</h1>
        </header>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="card">
            <div className="card-body">
              <h6>Organization</h6>
              <div className="form-group col-md-6 mb-3">
                <select
                  className="custom-select"
                  {...register('organization', { required: true })}
                  onChange={handleOrganizationChange}>
                  {organizations &&
                    organizations.organizations.map((organization) => (
                      <option
                        key={organization.organization_id}
                        value={organization.organization_id}>
                        {organization.organization_name}
                      </option>
                    ))}
                </select>
                {errors?.organization && errors.organization.type === 'required' && (
                  <p className="validate-feedback-invalid">Organization is required.</p>
                )}
              </div>

              <h6>Workspace</h6>
              <div className="form-group col-md-6 mb-3">
                <select
                  className="custom-select"
                  {...register('workspace', { required: true })}
                  onChange={handleWorkspaceChange}>
                  {workspaces &&
                    workspaces.workspaces.map((workspace) => (
                      <option
                        key={workspace.workspace_id}
                        value={workspace.workspace_id}>
                        {workspace.workspace_name}
                      </option>
                    ))}
                </select>
                {errors?.workspace && errors.workspace.type === 'required' && (
                  <p className="validate-feedback-invalid">Workspace is required.</p>
                )}
              </div>

              <Form.Group className="form-group col-md-6 mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  id="name"
                  placeholder="Report name"
                  {...register('name', { minLength: 3, required: true })}
                />
                {errors?.name && errors.name.type === 'required' && (
                  <p className="validate-feedback-invalid">Report name is required.</p>
                )}
                {errors?.name && errors.name.type === 'minLength' && (
                  <p className="validate-feedback-invalid">Report name must be at least 3 characters long.</p>
                )}
              </Form.Group>
              <Form.Group className="form-group col-md-6 mb-3">
                <Form.Label>Short name</Form.Label>
                <Form.Control
                  type="text"
                  id="shortName"
                  placeholder="Report short name"
                  {...register('shortName', {
                    maxLength: 2,
                    minLength: 2,
                    required: true
                  })}
                />
                {errors?.shortName &&
                  errors.shortName.type === 'required' && (
                    <p className="validate-feedback-invalid">
                      Report short name is required.
                    </p>
                  )}
                {errors?.shortName &&
                  errors.shortName.type === 'minLength' && (
                    <p className="validate-feedback-invalid">
                      Report short name must be 2 characters.
                    </p>
                  )}
                {errors?.shortName &&
                  errors.shortName.type === 'maxLength' && (
                    <p className="validate-feedback-invalid">
                      Report short name must be 2 characters.
                    </p>
                  )}
              </Form.Group>
              <Form.Group className="form-group col-md-6 mb-3">
                <Form.Label>Code</Form.Label>
                <Form.Control
                  as="select"
                  id="code"
                  className="custom-select"
                  {...register('code', { required: true })}
                  onChange={handleCodeChange}
                  value={watch('code')} // Ensure the dropdown displays the selected code
                >
                  {reportCodes &&
                    Array.isArray(reportCodes.reportCodes) &&
                    reportCodes.reportCodes.map((code, index) => (
                      <option
                        key={index}
                        value={code}
                      >
                        {reportCodes.reportNames[index]}
                      </option>
                    ))
                  }
                </Form.Control>
                {errors?.code && errors.code.type === 'required' && (
                  <p className="validate-feedback-invalid">Report code is required.</p>
                )}
              </Form.Group>
              <Form.Group className="form-group col-md-6 mb-3">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  type="text"
                  id="description"
                  placeholder="Report description"
                  {...register('description', { required: false })}
                />
              </Form.Group>
              
              <h6>Security</h6>
                <Form.Group className="form-group col-md-6 mb-3">
                  <Form.Check
                    bsPrefix="custom-control"
                    label="None"
                    name="reportSecurityType"
                    type="radio"
                    id="reportSecurityType"
                    defaultChecked={!(isRowLevelSecurity || isSSASSecurity)}
                    onChange={() => {
                      setIsRowLevelSecurity(false);
                      setIsSSASSecurity(false);
                    }}
                  />
                  <Form.Check
                    bsPrefix="custom-control"
                    label="Row-Level Security"
                    name="reportSecurityType"
                    type="radio"
                    id="reportSecurityType"
                    defaultChecked={isRowLevelSecurity}
                    onChange={() => {
                      setIsRowLevelSecurity(true);
                      setIsSSASSecurity(false);
                    }}
                  />
                  {isRowLevelSecurity && (
                    <Form.Group className="pl-3 pt-1">
                      <ReactTags
                        allowNew
                        ariaDescribedBy="Report roles"
                        closeOnSelect
                        id="report-roles"
                        labelText="Enter new roles"
                        placeholderText="Add role(s)"
                        onAdd={onAdd}
                        onDelete={onDelete}
                        selected={selected}
                        suggestions={[]}
                      />
                      <small className="text-muted">
                        Valid role(s) that will be used as the effective
                        identity.{' '}
                      </small>
                    </Form.Group>
                  )}
                  <Form.Check
                    bsPrefix="custom-control"
                    label="SQL Server Analysis Services (SSAS) - on-premises"
                    name="reportSecurityType"
                    type="radio"
                    id="reportSecurityType"
                    defaultChecked={isSSASSecurity}
                    onChange={() => {
                      setIsRowLevelSecurity(false);
                      setIsSSASSecurity(true);
                    }}
                  />
                  {isSSASSecurity && (
                    <Form.Group className="pl-3 pt-1">
                      <Form.Control
                        type="text"
                        id="username"
                        placeholder="username"
                        {...register('username', { required: false })}
                      />
                      <small className="text-muted">
                        A valid username recognized by the SSAS that will be
                        used as the effective identity.{' '}
                      </small>
                    </Form.Group>
                  )}
                </Form.Group>
                <hr className="mb-0 mt-0"></hr>
                <div className="form-actions">
                  <Button variant="primary" disabled={isUpdating} type="submit">
                    Save
                  </Button>
                  <Button
                    variant="secondary"
                    style={{ marginLeft: 10 }}
                    type="button"
                    onClick={handleCancel}>
                    Cancel
                  </Button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateEditReport;

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const PROC = process.env;
const API_URL = process.env.REACT_APP_DOT_NET_PORTAL_API;

// Define the API service using a base URL
export const activeDateApi = createApi({
  reducerPath: 'activeDateApi',
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders: (headers) => {
      headers.set('Content-Type', 'application/json'); // Set content type to JSON
      return headers;
    }
  }),
  endpoints: (builder) => ({
    authenticateUser: builder.mutation({
        query: ({ email, token }) => ({
          url: 'Authorization/auth',
          method: 'POST',
          body: {
            Email: email,   // Sending Email field as expected by the API
            Token: token    // Sending Token field
          },
          responseHandler: (response) => response.text(), // Expect plain text response (the token)
        }),
        // Handle the response and store the token
        async onQueryStarted(arg, { dispatch, queryFulfilled }) {
          try {
            console.log('asdfas');
            const { data } = await queryFulfilled;
            sessionStorage.setItem('Accesstoken', data); // Save the token in sessionStorage
            console.log('Authentication successful, token:', data); // Log token
            console.log('Normal Token: ' + sessionStorage.getItem('token'))
            console.log('Access Token: ' + sessionStorage.getItem('Accesstoken'))
          } catch (error) {
            console.error('Authentication failed:', error);
          }
        }
      }),
    
    // Endpoint to send the active date
    setActiveDate: builder.mutation({
        query: ({ userId, organisation }) => ({
          url: 'Service/setactive',
          method: 'POST',
          body: JSON.stringify({
            userId: userId, // Should be a valid user ID
            organisation: organisation // Should be a valid organization name
          }),
          headers: {
            'Authorization': `Bearer ${sessionStorage.getItem('Accesstoken')}`, // Correct token
            'Content-Type': 'application/json' // Content-Type must be JSON
          }
        })
        
      }),
      
  })
});

export const { useAuthenticateUserMutation, useSetActiveDateMutation } = activeDateApi;

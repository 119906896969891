import React, { useState } from 'react';
import { Breadcrumb, Button, Card, Modal, Nav, Tab } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import DataGrid from '../../Users/DataGrid';
import TimeAndSchedule from '../../TimeAndSchedule'; // Import the TimeAndSchedule component
import {
  useDeleteReportSubscriptionMutation,
  useGetReportSubscriptionQuery
} from '../../../services/processor.service';

const Subscriptions = () => {
  let navigate = useNavigate();
  let { reportType, reportId, reportCode, reportName } = useParams();
  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState(0);
  const [subscriptionModelShow, setSubscriptionModalShow] = useState(false);

  const { data: subscription } = useGetReportSubscriptionQuery(reportCode, {
    forceRefetch: true
  });
  const [deleteReportSubscription] = useDeleteReportSubscriptionMutation();

  const handleSubscriptionModalShow = () => {
    setSubscriptionModalShow(true);
  };

  const handleSubscriptionModalCancel = () => {
    setSubscriptionModalShow(false);
  };

  const handleNewSubscription = () => {
    navigate(
      // eslint-disable-next-line max-len
      `/notifications/subscriptions/reports/${reportType}/${reportId}/${reportCode}/${reportName}/new`
    );
  };

  const handleSubscriptionDelete = () => {
    deleteReportSubscription(selectedSubscriptionId)
      .unwrap()
      .then((payload) => {
        console.debug('Successfully deleted subscription', payload);
      })
      .catch((error) => console.error('rejected', error));

    setSubscriptionModalShow(false);
    window.scrollTo({ behavior: 'smooth', left: 0, top: 0 });
  };

  const columns = [
    { field: 'subscriptionName', header: 'Subscription name' },
    { field: 'contentName', header: 'Content name' },
    { field: 'type', header: 'Type' },
    { field: 'owner', header: 'Owner' },
    { field: 'createdAt', header: 'Created', dataType: 'date' },
    { field: 'schedule', header: 'Schedule', type: 'cron' },
  ];

  return (
    <div className="page">
      <div className="page-inner">
        <header className="page-title-bar">
          <Breadcrumb className="breadcrumb">
            <Breadcrumb.Item linkProps={{ to: '/' }} linkAs={Link}>
              <i className="breadcrumb-icon fa fa-angle-left mr-2"></i> Home
            </Breadcrumb.Item>
            <Breadcrumb.Item linkProps={{ to: '/reports' }} linkAs={Link}>
              Reports
            </Breadcrumb.Item>
            <Breadcrumb.Item className="active">Subscriptions</Breadcrumb.Item>
          </Breadcrumb>
          <div className="d-flex justify-content-between">
            <h1 className="page-title"> Subscriptions </h1>
            <div className="btn-toolbar">
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleNewSubscription}>
                Create a subscription
              </button>
            </div>
          </div>
          <p className="text-muted">
            Keep track of your data by subscribing to this report.
          </p>
        </header>
        <div className="page-section">
          <Card>
            <Tab.Container
              id="report-tabs"
              defaultActiveKey="subscriptions"
              transition={false}>
              <Card.Header>
                <Nav variant="tabs">
                  <Nav.Item>
                    <Nav.Link eventKey="subscriptions">
                      Power BI subscriptions
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Card.Header>
              <Card.Body>
                <Tab.Content>
                  <Tab.Pane eventKey="subscriptions">
                    <div className="table-responsive">
                      <DataGrid
                        data={subscription}
                        columns={columns}
                        showDelete={true}
                        onDeleteRow={(rowData) => {
                          setSelectedSubscriptionId(rowData.jobId);
                          handleSubscriptionModalShow();
                        }}
                        customRenderers={{
                          createdAt: (rowData) => (
                            <TimeAndSchedule
                              createdAt={rowData.createdAt}
                              schedule={rowData.schedule}
                            />
                          ),
                        }}
                      />
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Card.Body>
            </Tab.Container>
          </Card>
        </div>
      </div>

      <Modal
        show={subscriptionModelShow}
        onHide={handleSubscriptionModalCancel}
        animation={false}
        centered>
        <Modal.Header closeButton={false}>
          <Modal.Title>
            <h6>Delete subscription</h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            This will remove the subscription permanently and no further emails
            will be sent.
          </p>
          <hr className="mb-0 mt-0"></hr>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleSubscriptionModalCancel}>
            Cancel
          </Button>
          <Button variant="warning" onClick={handleSubscriptionDelete}>
            Delete subscription
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Subscriptions;

import React, { useState, useEffect } from 'react';
import { useGetUsersQuery, useUpdateUserStatusMutation } from '../../services/portal';
import { useNavigate } from 'react-router-dom';
import DataGrid from './DataGrid';
import { Modal, Button, Tab, Tabs } from 'react-bootstrap';

const UsersPage = () => {
  const [activeKey, setActiveKey] = useState('active'); // State to track active tab
  const navigate = useNavigate();
  const { data: users, refetch } = useGetUsersQuery({ forceRefetch: true });
  const [updateUserStatus] = useUpdateUserStatusMutation();
  const [selectedUserOrganization, setSelectedUserOrganization] = useState('');
  const [selectedUserId, setSelectedUserId] = useState(0);
  const [selectedUserFullName, setSelectedUserFullName] = useState('');
  const [selectedUserStatus, setSelectedUserStatus] = useState(false);
  const [userBlockModalShow, setUserBlockModalShow] = useState(false);
  const [activeUsers, setActiveUsers] = useState([]);
  const [blockedUsers, setBlockedUsers] = useState([]);
  const [modalAction, setModalAction] = useState(''); // 'disable' or 'activate'

  useEffect(() => {
    filterUsers();
  }, [users]);

  const handleUserNew = () => {
    navigate('/users/new');
  };

  const handleEditRow = (rowData) => {
    navigate(`/users/${rowData.user_id}`);
  };

  const handleDeleteRow = (rowData, action) => {
    const { user_id, organization, active } = rowData;
    setSelectedUserId(user_id);
    setSelectedUserOrganization(organization.organization_name);
    setSelectedUserFullName(`${rowData.first_name} ${rowData.last_name}`);
    setSelectedUserStatus(active);
    setModalAction(action);
    setUserBlockModalShow(true);
  };

  const handleUserBlockModalCancel = () => {
    setUserBlockModalShow(false);
  };

  const handleUpdateUserStatus = () => {
    updateUserStatus({
      user_id: selectedUserId,
      status: modalAction === 'disable' ? false : true,
    })
      .unwrap()
      .then(() => {
        console.debug('User status successfully updated');
        refetch();
        setUserBlockModalShow(false);
      })
      .catch((error) => console.error('Failed to update user status:', error));
  };

  const filterUsers = () => {
    if (!users || !users.users) return;
    const filteredActive = users.users.filter(user => user.active);
    const filteredBlocked = users.users.filter(user => !user.active);
    setActiveUsers(filteredActive);
    setBlockedUsers(filteredBlocked);
  };

  const handleTabSelect = (key) => {
    setActiveKey(key);
  };

  const columns = [
    { field: 'organization.organization_name', header: 'Organization' },
    { field: 'first_name', header: 'First Name' },
    { field: 'last_name', header: 'Last Name' },
    { field: 'phone', header: 'Phone' },
    { field: 'email', header: 'Email' },
    { field: 'organization.company', header: 'Company' },
    { field: 'organization.department', header: 'Department' },
    { field: 'role', header: 'Role' },
  ];

  return (
    <div className="page-inner">
      <header className="page-title-bar">
        <div className="d-flex justify-content-between">
          <h1 className="page-title ml-2 m-0">
            <i className="far fa-building text-muted mr-2"></i>Users
          </h1>
          <div>
            <button type="button" className="btn btn-primary m-0 align-right" onClick={handleUserNew}>
              + New
            </button>
          </div>
        </div>
      </header>
      <div className="page">
        <Tabs
          id="user-tabs"
          activeKey={activeKey}
          onSelect={handleTabSelect}
          className="mb-3"
        >
          <Tab eventKey="active" title="Active">
            <div className="card p-3">
              <DataGrid
                data={activeUsers}
                columns={columns}
                onEditRow={handleEditRow}
                onDeleteRow={(rowData) => handleDeleteRow(rowData, 'disable')}
                showDelete={true} // Disable button
                showEditRow={true}
              />
            </div>
          </Tab>
          <Tab eventKey="blocked" title="Blocked">
            <div className="card p-3">
              <DataGrid
                data={blockedUsers}
                columns={columns}
                onEditRow={handleEditRow}
                onDeleteRow={(rowData) => handleDeleteRow(rowData, 'activate')}
                showDelete={true} // Activate button
              />
            </div>
          </Tab>
        </Tabs>
      </div>

      <Modal
        show={userBlockModalShow}
        onHide={handleUserBlockModalCancel}
        animation={false}
        centered
      >
        <Modal.Header closeButton={false}>
          <Modal.Title>
            <h6>{modalAction === 'disable' ? 'Disable User' : 'Activate User'}</h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Are you sure you want to {modalAction === 'disable' ? 'disable' : 'activate'}{' '}
            <strong>{selectedUserFullName}</strong> from the{' '}
            <strong>{selectedUserOrganization}</strong> organization?
          </p>
          <hr className="mb-0 mt-0"></hr>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleUserBlockModalCancel}>
            Cancel
          </Button>
          <Button variant={modalAction === 'disable' ? 'danger' : 'success'} onClick={handleUpdateUserStatus}>
            {modalAction === 'disable' ? 'Disable' : 'Activate'}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UsersPage;

import { createBrowserHistory } from 'history';
import Parser from 'html-react-parser';
import { useContext, useState, useEffect, useCallback, useRef } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import avatar from '../../assets/images/avatars/person-circle.svg';
import ThemeContext from '../../contexts/ThemeContext';
import ThemeSetter from '../ThemeSetter/ThemeSetter';
import { useAuthenticateUserMutation, useSetActiveDateMutation } from '../../services/ActiveDateHandler'; // Updated import

const history = createBrowserHistory({ forceRefresh: true });

function getSessionStorageOrDefault(key, defaultValue) {
  const stored = sessionStorage.getItem(key);
  if (!stored) {
    return defaultValue;
  }
  return JSON.parse(stored);
}

const NavigationBar = () => {
  const { logo, logoText } = useContext(ThemeContext);
  const [user, setUser] = useState(getSessionStorageOrDefault('user', null));
  const [currentTime, setCurrentTime] = useState(null);
  const activityTimeoutRef = useRef(null);
  const activityIntervalRef = useRef(null);

  const [authenticateUser] = useAuthenticateUserMutation();
  const [setActiveDate] = useSetActiveDateMutation();

  useEffect(() => {
    const email = user?.email;
    const Accesstoken = sessionStorage.getItem('Accesstoken2'); 
    console.log('JP 1 '+email);
    console.log('JP 2 '+Accesstoken);
    
    if (email && !Accesstoken) {
      //console.log('asdfas');
      console.log('Authenticating user...');
      handleAuth(email);
    }
  }, [user]);

  const handleAuth = async (email) => {
    const token = sessionStorage.getItem('token'); // Retrieve the token if already stored
    if (!token) {
      console.error("No token found in session storage");
      return;
    }

    try {
      console.log('Calling authenticateUser mutation...');
      const authResponse = await authenticateUser({ email, token }).unwrap();
      console.log('Authentication response:', authResponse);

      const newToken = authResponse?.token;
      if (newToken) {
        sessionStorage.setItem('Accesstoken', newToken);
        console.log('Token stored:', newToken);
      }
    } catch (error) {
      console.error('Authentication error:', error);
    }
};
  

  // Function to set the active date
  const handleSetActiveDate = async () => {
    const token = sessionStorage.getItem('token'); // Retrieve the token
    if (user && token) {
      try {
        const UserID = ''+user.uid;
        const activeDateResponse = await setActiveDate({
          userId: UserID,
          organisation: user.organization,
        }).unwrap();

        console.log('Active date response:', activeDateResponse);
      } catch (error) {
        console.error('Error setting active date:', error);
      }
    }
  };

  // Function to update the current time and set the active date
  const updateTime = useCallback(() => {
    const currentDate = new Date();

    const day = currentDate.getDate().toString().padStart(2, '0');
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const year = currentDate.getFullYear();

    const hours = currentDate.getHours().toString().padStart(2, '0');
    const minutes = currentDate.getMinutes().toString().padStart(2, '0');
    const seconds = currentDate.getSeconds().toString().padStart(2, '0');

    const formattedDate = `${day}/${month}/${year}`;
    const formattedTime = `${hours}:${minutes}:${seconds}`;
    const formattedDateTime = `${formattedDate} ${formattedTime}`;

    setCurrentTime(formattedDateTime);

    // Set the active date when time is updated
    handleSetActiveDate();
  }, [user]);

  // Function to handle user activity
  const handleUserActivity = useCallback(() => {
    if (activityTimeoutRef.current) {
      clearTimeout(activityTimeoutRef.current);
    }

    if (!activityIntervalRef.current) {
      updateTime(); // Initial update when activity is detected
      activityIntervalRef.current = setInterval(updateTime, 10000); // Every 10 seconds
    }

    activityTimeoutRef.current = setTimeout(() => {
      if (activityIntervalRef.current) {
        clearInterval(activityIntervalRef.current);
        activityIntervalRef.current = null;
      }
    }, 10000); // Stop updates after 10 seconds of inactivity
  }, [updateTime]);

  useEffect(() => {
    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('keydown', handleUserActivity);
    window.addEventListener('focus', handleUserActivity);

    return () => {
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('keydown', handleUserActivity);
      window.removeEventListener('focus', handleUserActivity);

      if (activityIntervalRef.current) {
        clearInterval(activityIntervalRef.current);
      }
      if (activityTimeoutRef.current) {
        clearTimeout(activityTimeoutRef.current);
      }
    };
  }, [handleUserActivity]);

  const handleLogout = async (e) => {
    e.preventDefault();
    sessionStorage.clear();
    history.push('/login');
  };

  return (
    <Navbar collapseOnSelect fixed="top" expand="sm" bg="primary" variant="dark">
      <Navbar.Brand href="/">
        {logo && logo.length > 0 ? (
          <img src={logo} className="align-top d-inline-block" alt="Logo" height="35" width="180" align="bottom"></img>
        ) : (
          Parser(logoText || 'Business Intelligence Portal')
        )}
      </Navbar.Brand>
      <Nav className="mr-auto"></Nav>
      <Nav className="ms-auto">
        <Nav.Link style={{ paddingTop: '12px' }}>
          <ThemeSetter size={20} pt-2 />
        </Nav.Link>
        <div className="dropdown d-none d-sm-flex">
          <button type="button" className="btn-account" data-toggle="dropdown" variant="account" block="true">
            <span className="user-avatar">
              <img src={avatar} alt="Avatar" />
            </span>
            <span className="account-summary pr-lg-4 d-lg-block">
              <span className="account-name">{user.family_name}</span>
              <span className="account-description">{user.company ? user.company : user.organization}</span>
              <span className="account-description" style={{ fontSize: '8px' }}>Last Active: {currentTime}</span>
            </span>
          </button>
          <div className="dropdown-menu">
            <div className="dropdown-arrow d-lg-none"></div>
            <div className="dropdown-arrow ml-3 d-none d-lg-block"></div>
            {(user.role === 'admin' || user.role === 'manager') && (
              <h6 className="dropdown-header"> Administration </h6>
            )}
            {user.role === 'admin' && (
              <Link to="/organizations" className="dropdown-item">
                <span className="dropdown-icon far fa-building"></span>{' '}
                Organizations
              </Link>
            )}
            {(user.role === 'admin' || user.role === 'manager') && (
              <Link to="/users" className="dropdown-item">
                <span className="dropdown-icon fas fa-users"></span> Users
              </Link>
            )}
            {(user.role === 'admin' || user.role === 'manager') && (
              <Link to="/workspaces" className="dropdown-item">
                <span className="dropdown-icon far fa-object-group"></span>{' '}
                Workspaces
              </Link>
            )}
            {(user.role === 'admin' || user.role === 'manager') && (
              <Link to="/reports" className="dropdown-item">
                <span className="dropdown-icon fas fa-chart-pie"></span> Reports
              </Link>
            )}
            {(user.role === 'admin' || user.role === 'manager') && (
              <div className="dropdown-divider"></div>
            )}
            {(user.role === 'admin' || user.role === 'manager') && (
              <h6 className="dropdown-header"> Preferences </h6>
            )}
            {(user.role === 'admin' || user.role === 'manager') && (
              <Link to="/preferences/branding" className="dropdown-item">
                <span className="dropdown-icon fas fa-sliders-h"></span>{' '}
                Branding
              </Link>
            )}
            {(user.role === 'admin' || user.role === 'manager') && (
              <Link to="/preference/smtp" className="dropdown-item">
                <span className="dropdown-icon fas fa-envelope"></span> SMTP
              </Link>
            )}
            {(user.role === 'admin' || user.role === 'manager') && (
              <div className="dropdown-divider"></div>
            )}
            <Link to="/" className="dropdown-item">
              <span className="dropdown-icon fas fa-chart-pie"></span> My
              Reports
            </Link>
            <Link to="/" className="dropdown-item" onClick={handleLogout}>
              <span className="dropdown-icon fas fa-sign-out-alt"></span> Logout
            </Link>
            <div className="dropdown-divider"></div>
            <Link to="/support/ticket" className="dropdown-item">
              <span></span> Ticket Logger
            </Link>
            <a href="https://help.biportal.co.za" className="dropdown-item" target="_blank" rel="noopener noreferrer">
              <span></span> Help Center
            </a>
          </div>
        </div>
      </Nav>
    </Navbar>
  );
};

export default NavigationBar;

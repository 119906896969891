import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { authSlice } from './redux/AuthSlice';
import { portalApi } from './services/portal';
import { processorServiceApi } from './services/processor.service';
import { activeDateApi } from './services/ActiveDateHandler'; // Import activeDateApi

const reducers = combineReducers({
  auth: authSlice.reducer,
  [portalApi.reducerPath]: portalApi.reducer,
  [processorServiceApi.reducerPath]: processorServiceApi.reducer,
  [activeDateApi.reducerPath]: activeDateApi.reducer, // Add activeDateApi reducer
});

const store = configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(portalApi.middleware)
      .concat(processorServiceApi.middleware)
      .concat(activeDateApi.middleware), // Add activeDateApi middleware
  reducer: reducers,
});

setupListeners(store.dispatch);

export default store;

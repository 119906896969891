import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.REACT_APP_PORTAL_API;

const getEmbedToken = (workspaceId, workspaceCode, reportId, reportCode) => {
  return axios.get(
    API_URL +
      '/api/v1/reports/' +
      workspaceId +
      '/' +
      workspaceCode +
      '/' +
      reportId +
      '/' +
      reportCode +
      '/GenerateToken',
    {
      headers: authHeader()
    }
  );
};

const getFileToDownload = (
  workspaceId,
  reportId,
  format,
  paginatedReportConfiguration,
  powerBIReportConfiguration
) => {
  console.log('powerBIReportConfiguration'+JSON.stringify(powerBIReportConfiguration));
  console.log('workspaceId'+workspaceId);
  console.log('reportId'+reportId);
  console.log('format' +format)
  console.log('authHeader'+JSON.stringify(authHeader()));
  return axios.post(
    API_URL + '/api/v1/reports/' + workspaceId + '/' + reportId + '/ExportTo',
    {
      format: format,
      paginatedReportConfiguration: paginatedReportConfiguration,
      powerBIReportConfiguration: powerBIReportConfiguration
    },
    {
      headers: authHeader(),
      responseType: 'blob'
    }
  );
};

export default {
  getEmbedToken: getEmbedToken,
  getFileToDownload: getFileToDownload
};

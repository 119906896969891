import { useCallback, useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ReactTags } from 'react-tag-autocomplete';
import * as REPORT from '../../constants/reports';
import {
  useAddReportMutation,
  useGetOrganizationsQuery,
  useGetWorkspacesByOrganizationQuery,
  useGetAvailableReportCodesQuery
} from '../../services/portal';

const CreateReport = () => {
  let navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm();

  const [selectedOrganization, setSelectedOrganization] = useState(2);
  const [selectedWorkspace, setSelectedWorkspace] = useState(null);
  const [selectedWorkspaceCode, setSelectedWorkspaceCode] = useState(null);
  const [reportType, setReportType] = useState('');

  // Define the onChange handler for the report type dropdown
  const handleCodeChange = (e) => {
    const selectedCode = e.target.value;
    const selectedIndex = availableReportCodes.indexOf(selectedCode);
    if (selectedIndex !== -1) {
      const selectedReportType = reportCodes.reportType[selectedIndex];
      setReportType(selectedReportType);
    }
  };
  const [isRowLevelSecurity, setIsRowLevelSecurity] = useState();
  const [isSSASSecurity, setIsSSASSecurity] = useState();
  const [availableReportCodes, setAvailableReportCodes] = useState([]);
  const [selected, setSelected] = useState([]);


  const { data: organizations } = useGetOrganizationsQuery({
    forceRefetch: true
  });
  const { data: workspaces } = useGetWorkspacesByOrganizationQuery(
    selectedOrganization,
    {
      forceRefetch: true
    }
  );
  const { data: reportCodes, isLoading: isReportCodesLoading } = useGetAvailableReportCodesQuery(
    selectedWorkspaceCode,
    {
      forceRefetch: true
    }
  );
  const [addReport, { isLoading: isUpdating }] = useAddReportMutation();

  useEffect(() => {
    if (organizations) {
      setValue('organization', organizations.organizations[0].organization_id);
      setSelectedOrganization(organizations.organizations[0].organization_id);
    }
  }, [organizations]);

  useEffect(() => {
    if (workspaces && workspaces.workspaces.length > 0) {
      setValue('workspace', workspaces.workspaces[0].workspace_id);
      setSelectedWorkspace(workspaces.workspaces[0].workspace_id);
      setSelectedWorkspaceCode(workspaces.workspaces[0].workspace_code);
    }
  }, [workspaces]);

  useEffect(() => {
    console.log('Report Codes:', reportCodes);
    if (reportCodes && reportCodes.reportCodes) {
      console.log('Report Codes Data:', reportCodes.reportCodes);
      setAvailableReportCodes(reportCodes.reportCodes);
    }
  }, [reportCodes]);

  const handleOrganizationChange = (e) => {
    const selectedOrganizationId = e.target.value;
    setSelectedOrganization(selectedOrganizationId);
    setSelectedWorkspace(null);
    setSelectedWorkspaceCode(null);
  };

  const handleWorkspaceChange = (e) => {
    const selectedWorkspaceId = e.target.value;
    const selectedWorkspaceData = workspaces?.workspaces.find(workspaces => workspaces.workspace_id == selectedWorkspaceId);
    if (selectedWorkspaceData) {
      setSelectedWorkspace(selectedWorkspaceId);
      setSelectedWorkspaceCode(selectedWorkspaceData.workspace_code);
    }
  };

  const onSubmit = (formData) => {
    // Determine the index of the selected code in the reportCodes array
    const selectedIndex = availableReportCodes.indexOf(formData.code);
    // Get the corresponding report type using the index
    let selectedReportType = reportCodes.reportType[selectedIndex];
    if(selectedReportType == 'PowerBIReport')
    {
      selectedReportType = 'Report';
    }else{
      selectedReportType = 'Paginated Report';
    }
    
    // Now you can include selectedReportType in your addReport call
    addReport({
      report_name: formData.name,
      report_shortname: formData.shortName,
      report_code: formData.code,
      report_description: formData.description,
      report_type: selectedReportType, // Include the selected report type here
      report_rls: {
        enabled: isRowLevelSecurity,
        roles: reportType === REPORT.TYPE.PAGINATED ? [] : selected.map((select) => select.value)
      },
      report_ssas: {
        enabled: isSSASSecurity,
        username: formData.username
      },
      workspace: {
        workspace_id: formData.workspace
      },
      organization: {
        organization_id: formData.organization
      }
    })
      .unwrap()
      .then((payload) => {
        navigate('/reports');
      })
      .catch((error) => console.error('rejected', error));
  };
  

  const onAdd = useCallback(
    (newTag) => {
      setSelected([...selected, newTag]);
    },
    [selected]
  );

  const onDelete = useCallback(
    (tagIndex) => {
      setSelected(selected.filter((_, i) => i !== tagIndex));
    },
    [selected]
  );

  const handleCancel = () => {
    navigate('/reports');
  };

  return (
    <div className="page">
      <div className="page-inner">
        <header className="page-title-bar">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item active">
                <a href="/reports">
                  <i className="breadcrumb-icon fa fa-angle-left mr-2"></i>
                  Reports
                </a>
              </li>
            </ol>
          </nav>
          <div className="d-flex justify-content-between">
            <h1 className="page-title"> New Report </h1>
          </div>
        </header>
        <div className="page-section">
          <div id="labels" className="card">
            <div className="card-body">
              <Form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <h6>Report</h6>
                <Form.Group className="form-group col-md-6 mb-3">
                  <Form.Label>Organization</Form.Label>
                  <select
                    id="organization"
                    className="custom-select"
                    {...register('organization')}
                    onChange={handleOrganizationChange}>
                    {organizations?.organizations.map((item, key) => {
                      return (
                        <option key={key} value={item.organization_id}>
                          {item.organization_name}
                        </option>
                      );
                    })}
                  </select>
                </Form.Group>
                <Form.Group className="form-group col-md-6 mb-3">
                  <Form.Label>Workspace</Form.Label>
                  <select
                    id="workspace"
                    className="custom-select"
                    {...register('workspace')}
                    onChange={handleWorkspaceChange}>
                    {workspaces?.workspaces.map((item, key) => {
                      return (
                        <option key={key} value={item.workspace_id}>
                          {item.workspace_name}
                        </option>
                      );
                    })}
                  </select>
                </Form.Group>
                <Form.Group className="form-group col-md-6 mb-3">
                  <Form.Label>Report</Form.Label>
                 
<select
  id="code"
  className="custom-select"
  {...register('code', { required: true })}
  onChange={handleCodeChange} 
> 
  {reportCodes &&
    Array.isArray(reportCodes.reportNames) &&
    // Combine reportNames and reportCodes into an array of objects
    reportCodes.reportNames.map((name, index) => ({
      name,
      code: reportCodes.reportCodes[index]
    }))
    // Sort the array of objects alphabetically by name
    .sort((a, b) => a.name.localeCompare(b.name))
    // Map over the sorted array to render <option> elements
    .map(({ name, code }, index) => (
      <option key={index} value={code}>
        {name}
      </option>
    ))}
</select>



                  {errors?.code && errors.code.type === 'required' && (
                    <p className="validate-feedback-invalid">
                      Report code is required.
                    </p>
                  )}
                </Form.Group>
                <Form.Group className="form-group col-md-6 mb-3">
                  <Form.Label>Report Name</Form.Label>
                  <Form.Control
                    type="text"
                    id="name"
                    placeholder="Report name"
                    {...register('name', { minLength: 3, required: true })}
                  />
                  {errors?.name && errors.name.type === 'required' && (
                    <p className="validate-feedback-invalid">
                      Report name is required.
                    </p>
                  )}
                  {errors?.name && errors.name.type === 'minLength' && (
                    <p className="validate-feedback-invalid">
                      Report name must be at least 3 characters.
                    </p>
                  )}
                </Form.Group>
                <Form.Group className="form-group col-md-6 mb-3">
                  <Form.Label>Short name</Form.Label>
                  <Form.Control
                    type="text"
                    id="shortName"
                    placeholder="Report short name"
                    {...register('shortName', {
                      maxLength: 2,
                      minLength: 2,
                      required: true
                    })}
                  />
                  {errors?.shortName &&
                    errors.shortName.type === 'required' && (
                      <p className="validate-feedback-invalid">
                        Report short name is required.
                      </p>
                    )}
                  {errors?.shortName &&
                    errors.shortName.type === 'minLength' && (
                      <p className="validate-feedback-invalid">
                        Report short name must be exactly 2 characters.
                      </p>
                    )}
                </Form.Group>
                
                <Form.Group className="form-group col-md-6 mb-3">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    type="text"
                    id="description"
                    placeholder="Report description"
                    {...register('description', { required: false })}
                  />
                </Form.Group>
                
                <h6>Security</h6>
                <Form.Group className="form-group col-md-6 mb-3">
                  <Form.Check
                    bsPrefix="custom-control"
                    label="None"
                    name="reportSecurityType"
                    type="radio"
                    id="reportSecurityType"
                    defaultChecked={!(isRowLevelSecurity || isSSASSecurity)}
                    onChange={() => {
                      setIsRowLevelSecurity(false);
                      setIsSSASSecurity(false);
                    }}
                  />
                  <Form.Check
                    bsPrefix="custom-control"
                    label="Row-Level Security"
                    name="reportSecurityType"
                    type="radio"
                    id="reportSecurityType"
                    defaultChecked={isRowLevelSecurity}
                    onChange={() => {
                      setIsRowLevelSecurity(true);
                      setIsSSASSecurity(false);
                    }}
                  />
                  {isRowLevelSecurity && (
                    <Form.Group className="pl-3 pt-1">
                      <ReactTags
                        allowNew
                        ariaDescribedBy="Report roles"
                        closeOnSelect
                        id="report-roles"
                        labelText="Enter new roles"
                        placeholderText="Add role(s)"
                        onAdd={onAdd}
                        onDelete={onDelete}
                        selected={selected}
                        suggestions={[]}
                      />
                      <small className="text-muted">
                        Valid role(s) that will be used as the effective
                        identity.{' '}
                      </small>
                    </Form.Group>
                  )}
                  <Form.Check
                    bsPrefix="custom-control"
                    label="SQL Server Analysis Services (SSAS) - on-premises"
                    name="reportSecurityType"
                    type="radio"
                    id="reportSecurityType"
                    defaultChecked={isSSASSecurity}
                    onChange={() => {
                      setIsRowLevelSecurity(false);
                      setIsSSASSecurity(true);
                    }}
                  />
                  {isSSASSecurity && (
                    <Form.Group className="pl-3 pt-1">
                      <Form.Control
                        type="text"
                        id="username"
                        placeholder="username"
                        {...register('username', { required: false })}
                      />
                      <small className="text-muted">
                        A valid username recognized by the SSAS that will be
                        used as the effective identity.{' '}
                      </small>
                    </Form.Group>
                  )}
                </Form.Group>
                <hr className="mb-0 mt-0"></hr>
                <div className="form-actions">
                  <Button variant="primary" disabled={isUpdating} type="submit">
                    Save
                  </Button>
                  <Button
                    variant="secondary"
                    style={{ marginLeft: 10 }}
                    type="button"
                    onClick={handleCancel}>
                    Cancel
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateReport;

import React, { useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Link } from 'react-router-dom';

const DataGrid = ({ data, columns, onEditRow, onDeleteRow, onNotification,onRights, showDelete, showNotification,showEditRow,showRights }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filterData = () => {
    if (!data || !data.length) return [];
    return data.filter(item => {
      return Object.values(item).some(value => {
        if (typeof value === 'string') {
          return value.toLowerCase().includes(searchTerm.toLowerCase());
        }
        return false;
      });
    });
  };

  const filteredData = filterData();

  const ImgStyle = {
    padding: '10px',
    verticalAlign: 'middle',
    marginRight: 5
  };

  const ColStyle = {
    padding: '10px',
  };

  return (
    <div className="datatable-custom">
      <div className="m-0 justify-content-end d-flex align-items-end p-3">
        <input 
          type="text" 
          className="m-0 col-2 card d-flex align-items-end p-2"
          placeholder="Type to Search..." 
          value={searchTerm} 
          onChange={handleSearchChange} 
        />
        <span className="p-inputgroup-addon">
          <i className="pi pi-search"></i>
        </span>
      </div>
      <DataTable
        value={filteredData}
        removableSort
        tableClassName="table dataTable no-footer table-striped"
      >
        {columns.map(col => (
          <Column key={col.field} dataType={col.dataType} field={col.field} header={col.header} sortable style={ColStyle} />
        ))}
        
        
        <Column field="actions" header=" " body={(rowData) => (
          <div className="text-center">
            {}
            {showEditRow && (
              <button className="btn btn-sm btn-icon btn-secondary" onClick={() => onEditRow(rowData)}>
              <i className="fa fa-pencil-alt"></i>
              <span className="sr-only">Edit</span>
              </button>
            )}
            
            {/* Conditionally render delete button based on showDelete prop */}
            {showDelete && (
              <button className="btn btn-sm btn-icon btn-secondary" onClick={() => onDeleteRow(rowData)}>
                <i className="fa fa-trash-alt" style={{ marginBottom: '3px' }}></i>
                <span className="sr-only">Delete</span>
              </button>
            )}
            {/* Conditionally render notification button based on showNotification prop */}
            {showNotification && (
              <button className="btn btn-sm btn-icon btn-secondary" onClick={() => onNotification(rowData)}>        
              <i className="far fa-bell" style={{ marginBottom: '3px' }}></i>
              <span className="sr-only">Notifications</span>
              </button>
            )}
            {/* Conditionally render notification button based on showNotification prop */}
            {showRights && (
              <button className="btn btn-sm btn-icon btn-secondary" onClick={() => onRights(rowData)}>        
              <i className="fas fa-users" style={{ marginBottom: '3px' }}></i>
              <span className="sr-only">Rights</span>
              </button>
            )}
            
          </div>
        )} style={ColStyle} />
      </DataTable>
    </div>
  );
};

export default DataGrid;
